import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
var GerenciamentoFcCelulaComponent = /** @class */ (function () {
    function GerenciamentoFcCelulaComponent(usuarioService, pessoaService, celulaService, membroService, formadorComunitarioService, formadorAssistenteService, imagemService, celulaCriarLinkCadastroRequestBuilder, caminhoFormativoService, route, router, swtAlert2Service) {
        this.usuarioService = usuarioService;
        this.pessoaService = pessoaService;
        this.celulaService = celulaService;
        this.membroService = membroService;
        this.formadorComunitarioService = formadorComunitarioService;
        this.formadorAssistenteService = formadorAssistenteService;
        this.imagemService = imagemService;
        this.celulaCriarLinkCadastroRequestBuilder = celulaCriarLinkCadastroRequestBuilder;
        this.caminhoFormativoService = caminhoFormativoService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.formadoresComunitario = new Array();
        this.formadoresAssistente = new Array();
        this.membrosCelula = new Array();
        this.listaAniversariantesMes = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.pessoaBuilder = new PessoaBuilder();
        this.pessoaMembroSelecionada = this.pessoaBuilder.getInstance();
        this.celulaCriarLinkCadastroRequest =
            this.celulaCriarLinkCadastroRequestBuilder.getInstance();
    }
    GerenciamentoFcCelulaComponent.prototype.ngOnInit = function () {
        this.isLoaded = false;
        this.hasCelulaAssociada = true;
        this.initializer();
    };
    GerenciamentoFcCelulaComponent.prototype.initializer = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.carregarUsuario()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.carregarFormadorComunitario()];
                    case 3:
                        _a.sent();
                        if (!(this.formadorComunitario != undefined &&
                            this.formadorComunitario.casaCelulaId != null)) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.carregarCelula()];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this.obterLinkCadastroCelula()];
                    case 5:
                        _a.sent();
                        this.buscaFCs = {
                            celulaId: this.celula.id,
                            pageNumber: 0,
                            quantityOfElements: 10,
                        };
                        this.carregarFormadoresComunitario();
                        this.buscaFAs = {
                            celulaId: this.celula.id,
                            pageNumber: 0,
                            quantityOfElements: 10,
                        };
                        this.carregarFormadoresAssistente();
                        this.buscaMembros = {
                            celulaId: this.celula.id,
                            pageNumber: 0,
                            quantityOfElements: 4,
                        };
                        return [4 /*yield*/, this.carregarMembrosCelula()];
                    case 6:
                        _a.sent();
                        //await this.carregarAniversariantes();
                        this.isLoaded = true;
                        return [3 /*break*/, 8];
                    case 7:
                        this.hasCelulaAssociada = false;
                        _a.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    GerenciamentoFcCelulaComponent.prototype.carregarUsuario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuario = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    GerenciamentoFcCelulaComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuario.id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        if (!(this.pessoa.imagemId != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPessoaImagem(this.pessoa.imagemId)];
                    case 2:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.pessoa);
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        e_2 = _a.sent();
                        console.log(e_2);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    GerenciamentoFcCelulaComponent.prototype.carregarFormadorComunitario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.formadorComunitarioService.buscarCelulaPorPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        this.formadorComunitario = response;
                        if (!(this.formadorComunitario.pessoaImagemId != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPessoaImagem(this.formadorComunitario.pessoaImagemId)];
                    case 2:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.formadorComunitario);
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        e_3 = _a.sent();
                        console.log(e_3);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    GerenciamentoFcCelulaComponent.prototype.carregarCelula = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.celulaService.find(this.formadorComunitario.casaCelulaId)];
                    case 1:
                        response = _a.sent();
                        this.celula = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _a.sent();
                        console.log(e_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    GerenciamentoFcCelulaComponent.prototype.carregarFormadoresComunitario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, i, blogImage, e_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, this.formadorComunitarioService.buscarCelulaPorId(this.celula.id)];
                    case 1:
                        response = _a.sent();
                        this.formadoresComunitario = response;
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.formadoresComunitario.length)) return [3 /*break*/, 5];
                        if (!(this.formadoresComunitario[i].pessoaImagemId != null)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getPessoaImagem(this.formadoresComunitario[i].pessoaImagemId)];
                    case 3:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.formadoresComunitario[i]);
                        _a.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_5 = _a.sent();
                        console.log(e_5);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    GerenciamentoFcCelulaComponent.prototype.carregarFormadoresAssistente = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, i, blogImage, e_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, this.formadorAssistenteService.buscarCelulaPorId(this.celula.id)];
                    case 1:
                        response = _a.sent();
                        this.formadoresAssistente = response;
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.formadoresAssistente.length)) return [3 /*break*/, 5];
                        if (!(this.formadoresAssistente[i].pessoaImagemId != null)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getPessoaImagem(this.formadoresAssistente[i].pessoaImagemId)];
                    case 3:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.formadoresAssistente[i]);
                        _a.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_6 = _a.sent();
                        console.log(e_6);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    GerenciamentoFcCelulaComponent.prototype.carregarAniversariantes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, i, blogImage, e_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, this.membroService.aniversariantesCelula(this.celula.id)];
                    case 1:
                        response = _a.sent();
                        this.listaAniversariantesMes = response.entity;
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.listaAniversariantesMes.length)) return [3 /*break*/, 5];
                        if (!(this.listaAniversariantesMes[i].pessoaImagemId != null)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getPessoaImagem(this.listaAniversariantesMes[i].pessoaImagemId)];
                    case 3:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.listaAniversariantesMes[i]);
                        _a.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_7 = _a.sent();
                        console.log(e_7);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    /*** MEMBROS ***/
    GerenciamentoFcCelulaComponent.prototype.selecionarMembro = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.membroComunidade.pessoaId = pessoa.id;
                this.pessoaMembroSelecionada = pessoa;
                $("#listagem-pessoa-membro").modal("hide");
                return [2 /*return*/];
            });
        });
    };
    GerenciamentoFcCelulaComponent.prototype.salvarMembro = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        this.membroComunidade.casaCelulaId = this.celula.id;
                        if (!(this.membroComunidade.dataEntrada != null &&
                            this.membroComunidade.pessoaId != null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.membroService.create(this.membroComunidade)];
                    case 1:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
                        this.limparSelect2("nmembro_pessoa");
                        this.carregarMembrosCelula();
                        return [3 /*break*/, 3];
                    case 2:
                        if (this.membroComunidade.pessoaId == null) {
                            this.swtAlert2Service.errorAlert("O campo pessoa é obrigatório.");
                        }
                        else if (this.membroComunidade.dataEntrada == null) {
                            this.swtAlert2Service.errorAlert("O campo data de entrada é obrigatório.");
                        }
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    GerenciamentoFcCelulaComponent.prototype.atualizarMembro = function (membro) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.membroService.update(membro.id, membro)];
                    case 1:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        this.carregarMembrosCelula();
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    GerenciamentoFcCelulaComponent.prototype.removerMembro = function (membroId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!confirm("Deseja remover esse membro?")) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.membroService.delete(membroId)];
                    case 1:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        this.carregarMembrosCelula();
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    GerenciamentoFcCelulaComponent.prototype.carregarMembrosCelula = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, i, blogImage, response_1, caminhoFormativo, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 8, , 9]);
                        return [4 /*yield*/, this.membroService.listarDetalhesPorCelulaIdFromView(this.celula.id)];
                    case 1:
                        response = _a.sent();
                        this.membrosCelula = response;
                        this.totalDePaginasMembros = 1;
                        //this.membrosCelula = response.content;
                        //this.totalDePaginasMembros = response.totalPages;
                        if (this.membrosCelula.length > 0) {
                            this.membrosCelula = this.membrosCelula.sort(function (a, b) {
                                return a.pessoaNome.localeCompare(b.pessoaNome);
                            });
                        }
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.membrosCelula.length)) return [3 /*break*/, 7];
                        if (!(this.membrosCelula[i].pessoaImagemId != null)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getPessoaImagem(this.membrosCelula[i].pessoaImagemId)];
                    case 3:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.membrosCelula[i]);
                        _a.label = 4;
                    case 4: return [4 /*yield*/, this.caminhoFormativoService.porPessoaId(this.membrosCelula[i].pessoaId)];
                    case 5:
                        response_1 = _a.sent();
                        if (response_1 != null && response_1 != undefined) {
                            caminhoFormativo = response_1;
                            this.membrosCelula[i].feedback = caminhoFormativo.caminhoFormativoFeedback;
                        }
                        _a.label = 6;
                    case 6:
                        i++;
                        return [3 /*break*/, 2];
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error);
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    GerenciamentoFcCelulaComponent.prototype.cliqueNoBotaoMembrosListener = function (botao) {
        this.buscaMembros.pageNumber = botao.numero - 1;
        this.carregarMembrosCelula();
    };
    GerenciamentoFcCelulaComponent.prototype.limparSelect2 = function (nome) {
        $("select[name='" + nome + "']").empty();
    };
    GerenciamentoFcCelulaComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    GerenciamentoFcCelulaComponent.prototype.criarLinkCadastroCelula = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!(this.celulaCriarLinkCadastroRequest.dataValidadeInicioCadastro >
                            this.celulaCriarLinkCadastroRequest.dataValidadeFimCadastro)) return [3 /*break*/, 1];
                        this.swtAlert2Service.warningAlert("A data inicial de validade do link deve ser menor ou igual a data final de validade do link");
                        return [3 /*break*/, 4];
                    case 1:
                        if (!(this.celulaCriarLinkCadastroRequest.dataValidadeInicioCadastro !=
                            null &&
                            this.celulaCriarLinkCadastroRequest.dataValidadeFimCadastro != null)) return [3 /*break*/, 3];
                        this.celulaCriarLinkCadastroRequest.celulaId = this.celula.id;
                        return [4 /*yield*/, this.celulaService.criarLinkCadastroCelula(this.celulaCriarLinkCadastroRequest)];
                    case 2:
                        response = _a.sent();
                        this.tokenLinkCelulaResponse = response;
                        this.celulaCriarLinkCadastroRequest =
                            this.celulaCriarLinkCadastroRequestBuilder.getInstance();
                        return [3 /*break*/, 4];
                    case 3:
                        this.swtAlert2Service.warningAlert("É preciso informar as datas de validade, de início e fim, antes de criar o link de registro na célula.");
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_6.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    GerenciamentoFcCelulaComponent.prototype.obterLinkCadastroCelula = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.celulaService.obterLinkCadastroCelula(this.celula.id)];
                    case 1:
                        response = _a.sent();
                        this.tokenLinkCelulaResponse = response;
                        return [3 /*break*/, 3];
                    case 2:
                        err_7 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_7.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    GerenciamentoFcCelulaComponent.prototype.copyToClipboard = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.tokenLinkCelulaResponse != null &&
                    this.tokenLinkCelulaResponse.urlCadastro != undefined &&
                    this.tokenLinkCelulaResponse.urlCadastro != null &&
                    this.tokenLinkCelulaResponse.urlCadastro != "") {
                    navigator.clipboard.writeText(this.tokenLinkCelulaResponse.urlCadastro);
                    this.swtAlert2Service.successAlert("Link copiado");
                }
                else {
                    this.swtAlert2Service.warningAlert("Não há link de cadastro para copia. Crie um link e tente novamente.");
                }
                return [2 /*return*/];
            });
        });
    };
    return GerenciamentoFcCelulaComponent;
}());
export { GerenciamentoFcCelulaComponent };
